/** Sweetalert lib importing and store to global **/
import swal from 'sweetalert2';
const swalStyled = swal.mixin({
    confirmButtonClass: 'btn btn-swal',
    buttonsStyling: false,
    width: 320,
    padding: '1.25em',
    background: '#fff',
});
window.swal = swalStyled;
